import { useState, useEffect } from "react";
import useStore from "../../store";

export const useHeader = () => {
  const { totalCount } = useStore((state) => {
    const dataLength = state.data.length; // Длина массива data

    // Подсчет общего количества элементов (сумма quantity всех элементов)
    const totalCount = state.data.reduce(
      (total, item) => total + item.quantity,
      0,
    );

    return {
      dataLength,
      totalCount,
    };
  });
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const toggleContact = () => {
    setIsOpenContact(!isOpenContact);
  };

  const toggleForm = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Для плавной прокрутки
    });

    setIsOpenForm(!isOpenForm);
  };

  const closeForm = () => {
    setIsOpenForm(false);
  };

  return {
    totalCount,
    toggleForm,
    isOpenContact,
    isOpenForm,
    toggleContact,
    closeForm,
  };
};
