import { DISABLE_PAYMENT } from "lib/constants/constants";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";

import { useRequisitesStyles } from "./useRequisitesStyles";

type ModalState = "contacts" | "requisites" | "About" | "basket" | "";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const Requsites = ({ isOpen, className = "", handleClose }: IModalUIProps) => {
  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose("")();
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose("");

    if ((e.target as HTMLElement).classList.contains("req")) {
      handleClose("")();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalContainerSC onClick={onClickOverlay} className={className}>
        <ContainerSC>
          <ReplyIconSC onClick={handleClose("")} src="ReplyIcon.svg" />
          <TextSC>
            <p>ИП Кудрявцева Елена Викторовна</p>
            <p>Телефон: +79823577837</p>
            <p>Режим работы: Понедельник-Пятница, с 9:00 до 17:00</p>
            <p>Адрес: 454007, г. Челябинск, проспект Ленина, д. 2А/2</p>
            <p>Email: helen_kud@bk.ru</p>
            <p>ИНН: 450101845134</p>
            <p>ОГРНИП: 32345000001836</p>
          </TextSC>
          <TitleSC>Политика возврата</TitleSC>
          <TextSC>
            <b>
              <i>Отказ от заказа и возврат товара</i>
            </b>
            <br />
            <br />
            <p>
              — Отказ от заказа до момента его получения Вы можете отказаться от
              заказа заполнив форму обратной связи, или обратившись по телефону:
              +79823577837.
            </p>
            <br />{" "}
            <p>
              — Возврат товара надлежащего качества Вы можете вернуть товар
              надлежащего качества в момент самовывоза со склада, расположенного
              по адресу: 454007, г. Челябинск, проспект Ленина, д. 2А/2.
              Необходимо предъявить товар в ненарушенной оригинальной упаковке
              производителя (в том числе должна быть не нарушена упаковка,
              сохранены товарный вид и потребительские свойства).
            </p>{" "}
            <br />
            <p>
              — Возврат товара ненадлежащего качества при обнаружении недостатка
              (несоответствия требованиям к качеству) товара Вы можете в течение
              14 календарных дней обратиться по телефону: +79823577837.
            </p>{" "}
            <br />
            <p>
              При оформлении возврата любого товара Вам необходимо предъявить
              следующие документы: фискальный чек или счет-фактуру.
            </p>{" "}
            <br />
            <b>
              <i>Возврат денежных средств</i>
            </b>{" "}
            <br /> <br />
            <p>
              — Отказ от товара до получения если Вы отказались от заказа до
              момента получения, предварительно оплатив его банковской картой на
              сайте, денежные средства будут возвращены в течение 10 рабочих
              дней на банковскую карту, с которой был оплачен заказ.
            </p>{" "}
            <br />
            <p>
              — Отказ от товара после получения В случае оплаты заказа
              наличными, денежные средства будут возвращены Вам непосредственно
              при оформлении возврата. При оплате банковской картой возврат
              денежных средств осуществляется в течение 10 рабочих дней на
              банковскую карту, с которой был оплачен заказ.
            </p>{" "}
            <br />
            <p>
              — Возврат денежных средств при типе оплаты «Банковский перевод» 1.
              В случае если Ваш заказ предоплачен, и Вы желаете отказаться от
              товара, либо обнаружили фактическое отсутствие товара в
              поставленном заказе в момент самовывоза, Вам следует указать в
              графе товарной накладной «Товар» позиции, от принятия которых Вы
              отказываетесь/ фактически отсутствуют: количество, сумма, код
              причины отказа. Во всех экземплярах товарной накладной указать,
              каким образом удобнее получить возврат денежных средств: в счет
              платежа по следующему заказу или переводом на расчетный счет (не
              дожидаясь размещения следующего заказа).
              <br /> <br /> 2. Направить письмо по электронной почте в адрес
              поддержки сайта helen_kud@bk.ru с пожеланием о возврате денежных
              средств на расчетный счет. Денежные средства будут возвращены на
              расчетный счет в течение 10 рабочих дней с момента получения
              соответствующего письма по электронной почте.
            </p>
          </TextSC>
          <TitleSC>Условия доставки товаров</TitleSC>
          <TextSC>
            Доставка товаров и грузов осуществляется Почтой России, CDEK, а
            также самовывоз со склада. Доставка за счет средств покупателя.
          </TextSC>
          {DISABLE_PAYMENT === "false" && (
            <>
              <TitleSC>Инструкция по оплате банковской картой на сайте</TitleSC>
              <TextSC>
                <p>
                  Для онлайн оплаты на сайте принимаются банковские карты: Visa,
                  MasterCard, МИР. Прием платежей на сайте обеспечивает
                  процессинговый центр{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://paykeeper.ru/">
                    PayKeeper
                  </a>
                  . Для оплаты товара банковской картой при оформлении заказа
                  выберете способ оплаты: банковской картой. Обработка платежа
                  происходит на авторизованной странице банка, где Вам
                  необходимо ввести данные Вашей банковской карты:
                </p>{" "}
                <p>1. Номер карты</p>
                <p>2. Срок действия карты</p>
                <p>
                  Имя и фамилию владельца карты (латинскими буквами, как указано
                  на карте)
                </p>
                <p>
                  Код CVV2/CVC2 (Если на вашей карте код CVC / CVV отсутствует,
                  то, возможно, карта не пригодна для CNP транзакций (т.е. таких
                  транзакций, при которых сама карта не присутствует, а
                  используются её реквизиты), и вам следует обратиться в банк
                  для получения подробной информации.)
                </p>
                <br />
                <br />
                <img
                  style={{ width: "100%" }}
                  src="/reqCard.jpg"
                  alt=" Подсказка по оплате."
                />
                <br />
                <br />
                <p>
                  Если Ваша карта подключена к услуге 3D-Secure (технология
                  безопасного проведения интернет-платежей), Вы будите
                  автоматически переадресованы на страницу банка, выпустившего
                  карту, для прохождения процедуру аутентификации. Информацию о
                  правилах и методах дополнительной идентификации уточняйте в
                  банке, выдавшем Вам банковскую карту. <br /> <br />
                  Безопасность обработки интернет-платежей через платежный шлюз
                  банка гарантирована международным сертификатом безопасности
                  PCI DSS. Передача информации происходит с применением
                  технологии шифрования SSL. Эта информация не доступна
                  посторонним лицам.
                </p>
              </TextSC>
            </>
          )}
        </ContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  ReplyIconSC,
  TextSC,
  TitleSC,
} = useRequisitesStyles();

export default React.memo(Requsites);
