import styled from "styled-components";

const ExternalWrapperSC = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (min-width: 1890px) {
    display: none;
  }
`;

const SectionSC = styled.section<{ $isShow?: boolean }>`
  display: ${({ $isShow }) => ($isShow ? "block" : "none")};

  background: #fff;
  z-index: 1000;

  position: absolute;
  top: 12rem;
  right: 50%;
  transform: translate(50%, 0%);

  width: 42.5rem;
  /* height: 29.16rem; */
  padding: 3rem;

  @media (max-width: 812px) {
    width: calc(100% - 15px * 2);
    /* height: 30.01rem; */
    height: 50%;

    top: 6rem;

    margin: 0 auto;

    padding: 2rem;
    /* padding-bottom: 2.5rem; */
  }
`;

const TitleSC = styled("h4")`
  color: #0b0b0b;
  text-align: center;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.125rem;

  margin-bottom: 3.5rem;

  @media (max-width: 812px) {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;

    margin-bottom: 1.38rem;
  }
`;

const FormSC = styled("form")`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-around;

  /* width: 100%; */
  /* width: 89rem; */
  margin: 0 auto;

  @media (max-width: 812px) {
    height: 85%;

    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 80%;
  }
`;

const InputSC = styled("input")`
  /* height: 1.275rem;
  width: 100%;

  margin-bottom: 1rem;
  padding: 0.64rem 0 20px;
  border: none;
  border-bottom: 0.85px solid #000;

  font-size: 1rem;
  font-weight: 5мсмсм00;
  line-height: 1.25rem;

  color: #0c0e20;

  &::placeholder {
    color: #989898;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  @media (max-width: 812px) {
    width: 100%;

    padding: 0.5rem 1.56rem 20px 0;

    margin-bottom: 0.62rem;

    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.25rem;
  } */
`;

const ButtonSC = styled("button")`
  margin: 3rem auto 0;

  background: #000;
  width: 15rem;
  height: 3.25rem;

  color: #fff;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;

  cursor: pointer;

  &:hover {
    background-color: #242834;
  }

  @media (max-width: 424px) {
    width: 100%;
    /* max-width: 50%; */
    margin: 0 auto;
  }
`;

export const useFormFeedBackStyles = () => ({
  SectionSC,
  FormSC,
  TitleSC,
  InputSC,
  ButtonSC,
  ExternalWrapperSC,
});
