import styled from "styled-components";

const WrapperSC = styled("div")`
  position: relative;
  max-width: 600px;
  width: 90%;

  display: flex;
  flex-direction: column;
  /* min-height: 200px; */
  /* max-height: 80vh; */
  //height: 70vh;
  /* height: 50vh; */
  /* background-color: #fff; */
  padding: 0.94rem;
  padding-bottom: 0;

  color: #292929;

  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */

  //background: rgba(0, 0, 0, 0.6);
  background: #fff;
  backdrop-filter: blur(2.5px);

  @media (max-width: 626px) {
    padding: 0.6rem;
  }
`;

const VendorCodeSC = styled("h4")`
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 0.9375rem;
`;

const PriceSC = styled("h3")`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

const TitleSC = styled("h2")`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  margin-bottom: 0.62rem;

  width: 85%;

  @media (max-width: 500px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;

const DescriptionSC = styled("p")`
  font-size: 0.875rem;
  line-height: 1.25rem;

  ::-webkit-scrollbar {
    width: 8px; /*Ширина скроллбара*/
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e4e6eb;
    height: 26px; /* Высота ползунка */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /*   overflow-y: scroll;
  padding-bottom: 20px;
  height: 85%;

  @media (max-width: 626px) {
    height: 80%;
  } */
`;

const ExternalWrapperSC = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ButtonExit = styled("button")`
  background: none;
  background-image: url("ExitButton.svg");
  background-repeat: no-repeat;

  width: 1.87rem;
  height: 1.87rem;

  position: absolute;
  top: 0.94rem;
  right: 0.94rem;

  cursor: pointer;
`;

const ContainerRow = styled("div")`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: end;

  /* flex-shrink: 0; */
  flex: 0 0 auto;

  position: relative; /* делает контейнер относительным */
  bottom: 5px;
  padding: 8px 0 0; /* добавляет отступ от краев */
`;

const FlexGrow = styled("div")`
  /* flex: 1; */

  flex: 1 0 auto;

  overflow-y: scroll;
  padding-bottom: 20px;
  /* height: 85%; */
  max-height: 85%;

  @media (max-width: 626px) {
    height: 80%;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */

  &::-webkit-scrollbar {
    width: 0; /* Chrome и Safari */
  }
`;

const ImgSC = styled("img")`
  max-height: 150px;
  max-width: 150px;

  display: block;
  object-fit: contain;

  @media (max-width: 500px) {
    max-height: 100px;
    max-width: 100px;
  }
`;

const ContainerSC = styled("div")`
  display: flex;
  gap: 16px;
`;

const FlexDirectionColumnSC = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const useFullVisionStyle = () => ({
  WrapperSC,
  PriceSC,
  TitleSC,
  VendorCodeSC,
  DescriptionSC,
  ImgSC,
  ExternalWrapperSC,
  ButtonExit,
  ContainerRow,
  FlexGrow,
  ContainerSC,
  FlexDirectionColumnSC,
});
