import styled, { css } from "styled-components";

const HeaderSC = styled("header")`
  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  background: #040608;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  z-index: 9999;
  /* height: 88px; */
  height: 4rem;
`;

const WrapperHeaderSC = styled("div")`
  height: 100%;
  padding: 1.25rem 2.5rem;
  @media (max-width: 440px) {
    padding: 0;
  }
`;

const ContainerElementsSC = styled("div")`
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  color: #fff;
`;

const HeaderContactsSC = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 0 72px;
  align-items: center;

  margin-left: auto;

  @media (max-width: 820px) {
    gap: 0 2.19rem;
  }
`;

const LinkSC = styled("a")`
  display: flex;
  align-items: center;

  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 1890px) {
    display: none;
  }
`;

const ContainerSC = styled("div")`
  //width: 17.5rem;

  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1890px) {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin: 0 auto;
  }
`;

const ButtonSC = styled("button")`
  background: none;

  color: #fff;

  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  margin-left: auto;

  cursor: pointer;

  @media (max-width: 812px) {
    margin-right: auto;
    margin-left: 2.19rem;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
  @media (max-width: 370px) {
    font-size: 0.8rem;
    white-space: nowrap;
  }
`;

const DownContactSC = styled.div<{ $isShow?: boolean }>`
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;

  background: rgba(255, 255, 255, 0.1);
  height: 4rem;
  width: 24rem;

  display: ${({ $isShow }) => ($isShow ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 4.25rem;

    @media (max-width: 368px) {
      padding: 0.5rem 0;
    }

    a {
      color: #fff;
      text-align: center;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1.25rem;

      text-decoration: none;
    }
  }

  p {
    color: #fff;
    display: none;
  }

  @media (max-width: 1890px) {
    top: 0;
    left: 0;
    transform: none;
    /* width: 100%; */
    padding: 0.5rem 0;
    /* display: flex; */
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    background: black;
    border-top: 0.5px solid rgba(255, 255, 255, 0.4);

    width: 100%;
    height: auto;

    top: 6.5rem;

    left: 50%;
    transform: translate(-50%, -50%);

    padding: 0.5rem 0;
    /* display: flex; */
    justify-content: flex-start;
    align-items: flex-start;

    div {
      margin: 0 auto;
      padding: 0 6px;
    }

    p {
      font-size: 14px;
      text-align: center;

      display: block;
    }
  }
`;

const SpanCountSC = styled("span")`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background: rgba(37, 39, 39, 0.9);
  border-radius: 50%;

  position: absolute;
  right: -6px;
  bottom: -6px;

  height: 18px;
  width: 18px;
`;

export const useHeaderStyle = () => ({
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  LinkSC,
  ButtonSC,
  ContainerSC,
  SpanCountSC,
  DownContactSC,
});
