import { Controller, FormProvider } from "react-hook-form";

import { useFormFeedBackStyles } from "./FormFeedBackStyles";
import { useFormFeedback } from "./useFormFeedBack";

import NameInputSC from "./NameInputSC/NameInputSC";
import EmailInput from "./EmailInputSC/EmailInput";
import ThemeInput from "./ThemeInput/ThemeInput";

const FormFeedBack = ({ isOpenForm, closeForm }) => {
  const { method, handleSubmit, onSubmit } = useFormFeedback(closeForm);

  return (
    // <ExternalWrapperSC>
    <SectionSC $isShow={isOpenForm}>
      <TitleSC>Обратная связь</TitleSC>
      <FormProvider {...method}>
        <FormSC action="#" onSubmit={handleSubmit(onSubmit)}>
          <NameInputSC />
          <EmailInput />
          <ThemeInput />
          <ButtonSC type="submit">Отправить</ButtonSC>
        </FormSC>
      </FormProvider>
    </SectionSC>
    // </ExternalWrapperSC>
  );
};

const { SectionSC, FormSC, TitleSC, InputSC, ButtonSC, ExternalWrapperSC } =
  useFormFeedBackStyles();

export default FormFeedBack;
