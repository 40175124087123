import { DISABLE_PAYMENT, DOMAIN_NAME } from "lib/constants/constants";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useRequisitesStyles } from "./useRequisitesStyles";
import policyStore from "../../../store/policyStore";

type ModalState = "contacts" | "requisites" | "About" | "basket" | "";

type IMenuState = "main" | "offer" | "policy" | "menu" | "agreement";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const Requsites = ({ isOpen, className = "", handleClose }: IModalUIProps) => {
  const [menuState, setMenuState] = useState<IMenuState>("main");
  const { isPolicy, setPolicy } = policyStore();

  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setMenuState("main");
      handleClose("")();
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose("");

    if ((e.target as HTMLElement).classList.contains("req")) {
      setMenuState("main");
      handleClose("")();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalContainerSC onClick={onClickOverlay} className={className}>
        <ContainerSC>
          {menuState === "main" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}>
                <button onClick={() => setMenuState("menu")}>Документы</button>
                <ReplyIconSC
                  onClick={() => {
                    setMenuState("main");
                    handleClose("")();
                  }}
                  src="ReplyIcon.svg"
                />
              </div>
              <TextSC>
                <p>ИП Кудрявцева Елена Викторовна</p>
                <p>Телефон: +79823577837</p>
                <p>Режим работы: Понедельник-Пятница, с 9:00 до 17:00</p>
                <p>Адрес: 454007, г. Челябинск, проспект Ленина, д. 2А/2</p>
                <p>Email: helen_kud@bk.ru</p>
                <p>ИНН: 450101845134</p>
                <p>ОГРНИП: 32345000001836</p>
              </TextSC>
              <TitleSC>Политика возврата</TitleSC>
              <TextSC>
                <b>
                  <i>Отказ от заказа и возврат товара</i>
                </b>
                <br />
                <br />
                <p>
                  — Отказ от заказа до момента его получения Вы можете
                  отказаться от заказа заполнив форму обратной связи, или
                  обратившись по телефону: +79823577837.
                </p>
                <br />{" "}
                <p>
                  — Возврат товара надлежащего качества Вы можете вернуть товар
                  надлежащего качества в момент самовывоза со склада,
                  расположенного по адресу: 454007, г. Челябинск, проспект
                  Ленина, д. 2А/2. Необходимо предъявить товар в ненарушенной
                  оригинальной упаковке производителя (в том числе должна быть
                  не нарушена упаковка, сохранены товарный вид и потребительские
                  свойства).
                </p>{" "}
                <br />
                <p>
                  — Возврат товара ненадлежащего качества при обнаружении
                  недостатка (несоответствия требованиям к качеству) товара Вы
                  можете в течение 14 календарных дней обратиться по телефону:
                  +79823577837.
                </p>{" "}
                <br />
                <p>
                  При оформлении возврата любого товара Вам необходимо
                  предъявить следующие документы: фискальный чек или
                  счет-фактуру.
                </p>{" "}
                <br />
                <b>
                  <i>Возврат денежных средств</i>
                </b>{" "}
                <br /> <br />
                <p>
                  — Отказ от товара до получения если Вы отказались от заказа до
                  момента получения, предварительно оплатив его банковской
                  картой на сайте, денежные средства будут возвращены в течение
                  10 рабочих дней на банковскую карту, с которой был оплачен
                  заказ.
                </p>{" "}
                <br />
                <p>
                  — Отказ от товара после получения В случае оплаты заказа
                  наличными, денежные средства будут возвращены Вам
                  непосредственно при оформлении возврата. При оплате банковской
                  картой возврат денежных средств осуществляется в течение 10
                  рабочих дней на банковскую карту, с которой был оплачен заказ.
                </p>{" "}
                <br />
                <p>
                  — Возврат денежных средств при типе оплаты «Банковский
                  перевод» 1. В случае если Ваш заказ предоплачен, и Вы желаете
                  отказаться от товара, либо обнаружили фактическое отсутствие
                  товара в поставленном заказе в момент самовывоза, Вам следует
                  указать в графе товарной накладной «Товар» позиции, от
                  принятия которых Вы отказываетесь/ фактически отсутствуют:
                  количество, сумма, код причины отказа. Во всех экземплярах
                  товарной накладной указать, каким образом удобнее получить
                  возврат денежных средств: в счет платежа по следующему заказу
                  или переводом на расчетный счет (не дожидаясь размещения
                  следующего заказа).
                  <br /> <br /> 2. Направить письмо по электронной почте в адрес
                  поддержки сайта helen_kud@bk.ru с пожеланием о возврате
                  денежных средств на расчетный счет. Денежные средства будут
                  возвращены на расчетный счет в течение 10 рабочих дней с
                  момента получения соответствующего письма по электронной
                  почте.
                </p>
              </TextSC>
              <TitleSC>Условия доставки товаров</TitleSC>
              <TextSC>
                Доставка товаров и грузов осуществляется Почтой России, CDEK, а
                также самовывоз со склада. Доставка за счет средств покупателя.
              </TextSC>
              <TitleSC>Схема проезда</TitleSC>
              <br />
              <TextSC>454007, г. Челябинск, проспект Ленина, д. 2А/2</TextSC>
              <br />
              <br />
              <img
                style={{ width: "100%", marginBottom: "16px" }}
                src="/map.png"
                alt=" Подсказка по оплате."
              />
              <TitleSC>Инструкция по оплате банковской картой на сайте</TitleSC>
              <TextSC>
                <p>
                  Для онлайн оплаты на сайте принимаются банковские карты: Visa,
                  MasterCard, МИР. Прием платежей на сайте обеспечивает
                  процессинговый центр{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://paykeeper.ru/">
                    PayKeeper
                  </a>
                  . Для оплаты товара банковской картой при оформлении заказа
                  выберете способ оплаты: банковской картой. Обработка платежа
                  происходит на авторизованной странице банка, где Вам
                  необходимо ввести данные Вашей банковской карты:
                </p>{" "}
                <p>1. Номер карты</p>
                <p>2. Срок действия карты</p>
                <p>
                  Имя и фамилию владельца карты (латинскими буквами, как указано
                  на карте)
                </p>
                <p>
                  Код CVV2/CVC2 (Если на вашей карте код CVC / CVV отсутствует,
                  то, возможно, карта не пригодна для CNP транзакций (т.е. таких
                  транзакций, при которых сама карта не присутствует, а
                  используются её реквизиты), и вам следует обратиться в банк
                  для получения подробной информации.)
                </p>
                <br />
                <br />
                <img
                  style={{ width: "100%" }}
                  src="/reqCard.jpg"
                  alt=" Подсказка по оплате."
                />
                <br />
                <br />
                <p>
                  Если Ваша карта подключена к услуге 3D-Secure (технология
                  безопасного проведения интернет-платежей), Вы будите
                  автоматически переадресованы на страницу банка, выпустившего
                  карту, для прохождения процедуру аутентификации. Информацию о
                  правилах и методах дополнительной идентификации уточняйте в
                  банке, выдавшем Вам банковскую карту. <br /> <br />
                  Безопасность обработки интернет-платежей через платежный шлюз
                  банка гарантирована международным сертификатом безопасности
                  PCI DSS. Передача информации происходит с применением
                  технологии шифрования SSL. Эта информация не доступна
                  посторонним лицам.
                </p>
              </TextSC>
            </>
          ) : menuState === "menu" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <button onClick={() => setMenuState("main")}>Назад</button>
                <ReplyIconSC
                  onClick={() => {
                    setMenuState("main");
                    handleClose("")();
                  }}
                  src="ReplyIcon.svg"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                  gap: "4px",
                }}>
                <TextSC
                  style={{ cursor: "pointer" }}
                  onClick={() => setMenuState("offer")}>
                  Публичная оферта
                </TextSC>
                <TextSC
                  style={{ cursor: "pointer" }}
                  onClick={() => setMenuState("agreement")}>
                  Договор купли-продажи
                </TextSC>
                <TextSC
                  style={{ cursor: "pointer" }}
                  onClick={() => setMenuState("policy")}>
                  Политика конфиденциальности
                </TextSC>
              </div>
            </>
          ) : menuState === "offer" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}>
                <button onClick={() => setMenuState("menu")}>Назад</button>
                <ReplyIconSC
                  onClick={() => {
                    setMenuState("main");
                    handleClose("")();
                  }}
                  src="ReplyIcon.svg"
                />
              </div>
              <TextSC>
                <TitleSC>Публичная оферта</TitleSC>
                <p>
                  Настоящий документ является предложением ИП Кудрявцева Елена
                  Викторовна (далее – «Компания») в адрес зарегистрированных и
                  незарегистрированных пользователей (далее – «пользователи»,
                  «вы») сайта, расположенного на домене{" "}
                  <a href={`https://${DOMAIN_NAME}/`}>https://{DOMAIN_NAME}/</a>{" "}
                  и всех его поддоменах (далее – «сайт») заключить рамочный
                  договор оказания услуг (далее – «Договор») на указанных ниже
                  условиях (далее – «Оферта»).
                </p>
                <br />
                <p>
                  Термины и определения, применяемые в настоящем Договоре
                  термины и определения, используются в следующем их значении:
                </p>
                <br />
                <p>
                  «Сайт» одна или несколько логически связанных между собой
                  веб-страниц, объединенных общей тематикой, расположенный под
                  одним адресом, доступный пользователем с помощью интернет
                </p>
                <br />
                <p>
                  «Товары\Услуги» ассортимент продукции или услуг, размещенный
                  на сайте {DOMAIN_NAME}, предназначенный для реализации для
                  физических или юридических лиц путем оформления заказа онлайн
                </p>
                <br />
                <b>
                  <i>1.Предмет Договора</i>
                </b>
                <br />
                <br />
                <p>
                  1.1. Договор определяет общие условия обязательственных
                  отношений, возникающих между Компанией и Пользователем при
                  оказании Компанией реализации товаров с помощью сайта{" "}
                  {DOMAIN_NAME}.
                </p>
                <p>
                  1.2. Заключаемый Договор представляет собой договор с
                  открытыми условиями. Существенные условия каждой совершаемой
                  на сайте {DOMAIN_NAME} сделки по оказанию Услуг формируются
                  онлайн с помощью интерфейса сайта {DOMAIN_NAME}, посредством
                  которого Пользователь выбирает интересующий его товар.
                </p>
                <br />

                <b>
                  <i>2.Заключение Договора. Заказ Услуг</i>
                </b>
                <br />
                <br />
                <p>
                  2.1. Оферта считается акцептованной Пользователем, а Договор
                  между Компанией и Пользователем, заключенным с момента оплаты
                  пользователем товара по соответствующей сделке (в этом случае
                  она совершается одновременно с заключением Договора) или с
                  момента внесения аванса в счет оплаты заказываемых в будущем
                  товаров. Условия заключенного Договора применяются ко всем
                  последующим сделкам между Пользователем и Компанией. Договор
                  может быть заключен только с Пользователем, являющимся
                  дееспособным физическим лицом либо юридическим лицом или
                  индивидуальным предпринимателем.
                </p>
                <p>
                  2.2. Каждый заказ конкретных товаров в рамках Договора
                  является самостоятельной сделкой. Сделка во исполнение
                  настоящего Договора может быть заключена в отношении товаров,
                  доступных для заказа и оплаты на сайте {DOMAIN_NAME} в режиме
                  онлайн.
                </p>
                <p>
                  2.3. Оплата заказа признается акцептом Пользователя
                  предложения о заключении сделки на согласованных сторонами
                  условиях.
                </p>
                <p>
                  2.4. Пользователь оформляет каждый заказ товаров в
                  соответствии с условиями действующей редакции Оферты и
                  условиями соответствующего сервиса. Условия всех сервисов
                  {DOMAIN_NAME}, опубликованные на сайте {DOMAIN_NAME} в момент
                  заказа товаров, являются неотъемлемой частью оферты.
                </p>
                <p>
                  2.5. Совершая акцепт Оферты, Пользователь выражает согласие с
                  тем, что факт выполнения определенных действий на сайте
                  {DOMAIN_NAME}, в том числе с использованием профессиональных
                  технических инструментов, выполнение команд через интерфейс
                  сайта {DOMAIN_NAME} (нажатие кнопок, клики по ссылкам),
                  совершение платежа и иные подобные действия означают ваше
                  волеизъявление в отношении заказа товаров в соответствии с
                  указанными на сайте {DOMAIN_NAME} параметрами товаров и их
                  ценой.
                </p>
                <br />
                <b>
                  <i>3.Общие условия оказания Услуг</i>
                </b>
                <br />
                <br />
                <p>
                  3.1. Услуги оказываются на территории Российской Федерации.
                </p>
                <p>
                  3.2. При оплате заказа банковской картой обработка платежа
                  происходит на защищенной странице процессинговой системы,
                  которая прошла международную сертификацию. Это значит, что
                  конфиденциальные данные Пользователя (реквизиты карты,
                  регистрационные данные и др.) не поступают в Компанию, их
                  обработка полностью защищена, и никто не может получить
                  персональные и банковские данные Пользователя.
                </p>
                <p>
                  3.3. Предоставляемые на сайте {DOMAIN_NAME} товары могут
                  изменяться, дополняться, обновляться, в связи с чем их
                  использование предлагается в режиме «как есть», то есть в том
                  виде и объеме, в каком они предоставляются Компанией в момент
                  обращения Пользователя к услугам и сервисам {DOMAIN_NAME}.
                </p>
                <p>
                  3.4. Компания вправе направлять Пользователю по имеющимся у
                  нее контактным данным уведомления, связанные с исполнением
                  настоящего Договора или любой из заключенных в рамках Договора
                  сделок, включая оповещения о статусе оказания Услуги, способах
                  продления, изменениях условий оказания Услуг и иные
                  информационные сообщения, посредством смс- и push-сообщений,
                  электронной почты, звонков и сообщений в мессенджерах и
                  телефонных звонков.
                </p>
                <p>
                  3.5. Пользователь обязуется соблюдать при пользовании услугами
                  требования действующего законодательства Российской Федерации,
                  положения Договора, соответствующие условия выбранной Услуги
                  или сервиса и все иные правила сайта {DOMAIN_NAME}.
                </p>
                <p>
                  3.6. По требованию Компании Пользователь обязан предоставить
                  информацию и документы, необходимые для идентификации
                  пользователя в качестве стороны Договора, в частности при
                  направлении в адрес Компании заявлений, запросов и прочих
                  обращений, связанных с оказанием Услуг на сайте {DOMAIN_NAME}.
                  В целях идентификации частных пользователей Компанией могут
                  быть запрошены ФИО, серия и номер паспорта, скан-копия второй
                  и третьей страницы паспорта (второй разворот), адрес
                  электронной почты и номер телефона пользователя, используемые
                  для доступа к профилю. Кроме того, Компания вправе
                  самостоятельно проводить верификацию Пользователя в качестве
                  стороны по Договору путем проведения сверки, указанных
                  Пользователем в профиле реквизитов с реквизитами плательщика
                  по Договору, а также иными способами.
                </p>
                <p>
                  3.7. Компания, являясь оператором персональных данных,
                  осуществляет обработку персональных данных Пользователя с
                  целью исполнения договора согласно Политике
                  конфиденциальности, размещенной на сайте {DOMAIN_NAME}.
                </p>
                <br />
                <b>
                  <i>4. Стоимость и оплата товаров</i>
                </b>
                <br />
                <br />
                <p>
                  4.1. Стоимость выбранных товаров в российских рублях
                  отображается в интерфейсе сайта {DOMAIN_NAME} при оформлении
                  или оплате заказа, если иное не предусмотрено условиями
                  соответствующего сервиса или Услуги.
                </p>
                <p>
                  4.2. Стоимость включает НДС 20%, уплачиваемый на территории
                  РФ.
                </p>
                <p>
                  4.3. Оплата товаров осуществляется на условиях предоплаты по
                  ценам, действующим на момент совершения платежа.
                </p>
                <br />
                <b>
                  <i>5. Способы оплаты товаров</i>
                </b>
                <br />
                <br />
                <p>
                  5.1. Пользователь, являющийся юридическим лицом или
                  индивидуальным предпринимателем, может осуществлять оплату
                  товаров по Договору безналичным переводом денежных средств на
                  основании выставленного счета. Компания вправе установить
                  минимальный объем товаров, оплачиваемых данным способом.
                  Допускается также оплата с использованием корпоративной
                  банковской карты, оформленной на соответствующее юридическое
                  лицо или индивидуального предпринимателя.
                </p>
                <br />
                <b>
                  <i>6. Возврат денежных средств</i>
                </b>
                <br />
                <br />
                <p>
                  6.1. После оплаты товара пользователь имеет право в течение 24
                  часов вернуть денежные средства, написав в службу поддержки,
                  контакты которой размещены на странице сайта {DOMAIN_NAME}{" "}
                  (раздел покупателям), с адреса электронной почты, указанной
                  при регистрации либо оплате, письмо с просьбой сделать
                  возврат, указав мотивированную причину отказа. Если подобной
                  заявки в течении 24 часов после оплаты товара не поступило,
                  Компания оставляет за собой право не возвращать оплаченную
                  сумму.
                </p>
                <p>
                  6.2. Компания оставляет за собой право отказать в частичном
                  возврате денежных средств без объяснения причин и
                  рассматривает это в частном порядке по письменному заявлению
                  от Пользователя.
                </p>
                <p>
                  6.3. Ответственность за получение любых документов (в том
                  числе актов, сообщений, уведомлений) вышеуказанными способами
                  лежит на Пользователе. Компания не несет ответственности за
                  задержку или неполучение Пользователем документов, если это
                  явилось результатом неисправности систем связи,
                  действия/бездействия провайдеров, операторов связи, утраты
                  Пользователем доступа к профилю или иных обстоятельств,
                  находящихся вне зоны контроля Компании.
                </p>
                <br />
                <b>
                  <i>7. Ответственность</i>
                </b>
                <br />
                <br />
                <p>
                  7.1. Компания обязуется предпринимать все разумные усилия для
                  надлежащего оказания Услуг, однако не отвечает и не
                  компенсирует убытки пользователя в случае, если Пользователь
                  не может воспользоваться Услугами по следующим причинам:
                </p>
                <ul>
                  <li>
                    технологические неисправности каналов связи общего
                    пользования, посредством которых осуществляется доступ к
                    товарам, утраты Пользователем доступа в интернет по любой
                    причине, ошибки, пропуски, перерывы в работе или передаче
                    данных, дефекты линий связи и иные технические сбои;
                  </li>
                  <li>
                    несанкционированное вмешательство в работу сайта{" "}
                    {DOMAIN_NAME}
                    третьих лиц, включая хакерские, DDoS-атаки, действия
                    вирусных программ и иные нарушения работы сайта{" "}
                    {DOMAIN_NAME};
                  </li>
                  <li>при наступлении обстоятельств непреодолимой силы.</li>
                </ul>
                <p>
                  7.2. В случае причинения убытков Пользователю по вине Компании
                  ответственность определяется в порядке, предусмотренном
                  законодательством РФ. При этом ответственность Компании перед
                  Пользователями, сайт {DOMAIN_NAME} для предпринимательской и
                  иной профессиональной деятельности, определяется в размере, не
                  превышающем стоимость заказанной и оплаченной Пользователем,
                  но не полученной по вине Компании.
                </p>
                <p>
                  7.3. Компания не отвечает перед Пользователем за убытки,
                  возникшие у Пользователя не по вине Компании, в том числе в
                  связи с нарушением Пользователем правил сайта {DOMAIN_NAME}.
                </p>
                <p>
                  7.4. Компания не несет ответственности за перевод или
                  поступление денежных средств Пользователя в оплату товаров.
                  Безопасность, конфиденциальность, а также иные условия
                  использования выбранных Пользователем способов оплаты
                  определяются соглашениями между Пользователем и
                  соответствующими организациями по приему платежей.
                </p>
                <br />
                <b>
                  <i>8. Рассмотрение претензий</i>
                </b>
                <br />
                <br />
                <p>
                  8.1. Отношения Сторон, вытекающие из настоящего Договора, а
                  также тесно связанные с ними, регулируются действующим
                  законодательством РФ.
                </p>
                <p>
                  8.2. Споры, возникшие в рамках настоящего Договора,
                  рассматриваются и разрешаются в арбитражном суде г.
                  Челябинска.
                </p>
                <p>
                  8.3. До обращения в суд Пользователь с профилем юридического
                  лица или индивидуального предпринимателя должен соблюсти
                  внесудебный порядок разрешения споров, направив в адрес
                  Компании письменную претензию, с приложением документов,
                  подтверждающих обстоятельства и требования, на которые
                  ссылается Пользователь. Срок рассмотрения таких претензий
                  составляет не более 30 дней с момента получения претензии
                  Компанией.
                </p>
                <br />
                <b>
                  <i>9.Прочие условия</i>
                </b>
                <br />
                <br />
                <p>
                  9.1. Договор вступает в силу с момента его заключения в
                  порядке, указанном в разделе 2 Оферты, и действует в течение
                  неопределенного срока до момента его прекращения в
                  соответствии с Договором и законодательством.
                </p>
                <p>
                  9.2. Компания вправе изменять и/или дополнять Оферту в той
                  мере, в какой это допускается законодательством, а также
                  отозвать Оферту в любое время. Датой изменения Оферты является
                  дата публикации на сайте {DOMAIN_NAME} новой редакции Оферты.
                  Вы должны ознакомиться с действующей редакцией Оферты,
                  размещенной на сайте {DOMAIN_NAME}, и с условиями каждой
                  сделки до заключения Договора и совершения сделки.
                </p>
                <p>
                  9.3. Заказ товаров и совершение Пользователем сделки в рамках
                  Договора после вступления в силу изменений в Оферте означает,
                  что стороны пришли к согласию о внесении соответствующих
                  изменений и/или дополнений в Договор. Такие изменения в
                  Договоре не применяются к товарам, заказанным и оплаченным
                  Пользователем до вступления в силу изменений в Оферте.
                </p>
              </TextSC>
            </>
          ) : menuState === "policy" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}>
                <button onClick={() => setMenuState("menu")}>Назад</button>
                <ReplyIconSC
                  onClick={() => {
                    setMenuState("main");
                    handleClose("")();
                  }}
                  src="ReplyIcon.svg"
                />
              </div>
              <TextSC>
                <TitleSC>Согласие на обработку персональных данных</TitleSC>
                <p>
                  Настоящим я даю свое согласие ИП Кудрявцевой Елене Викторовне
                  (ОГРНИП 32345000001836, ИНН 450101845134), адрес: 641310
                  Курганская обл, Кетовский р-н, село Кетово, ул Космонавтов, д
                  45, кв 10 (далее – Оператор) на обработку Оператором (включая
                  получение от меня и/или от любых третьих лиц, с учетом
                  требований действующего законодательства Российской Федерации)
                  моих персональных данных и подтверждаю, что, давая такое
                  согласие, я действую своей волей и в своем интересе.
                </p>
                <br />
                <p>
                  Согласие дается мною для целей заключения с Оператором и/или
                  его партнерами по лицензионным договорам любых договоров,
                  направленных на реализацию (продажу) мне или другим лицам
                  товаров, а также и иных сделок, совершения иных действий,
                  порождающих юридические последствия в отношении меня или
                  других лиц, предоставления мне информации об оказываемых
                  Оператором и/или его партнерами по лицензионным договорам
                  услугах и реализуемых товарах и распространяется на следующую
                  информацию: мои фамилия, имя, отчество, год, месяц, дата и
                  место рождения, адрес и любая иная информация, относящаяся к
                  моей личности, доступная либо известная в любой конкретный
                  момент времени Оператору (далее - «Персональные данные»),
                  предусмотренная Федеральным законом от 27.07.2006 N 152-ФЗ «О
                  персональных данных».
                </p>
                <br />
                <p>
                  Настоящим я признаю и подтверждаю, что в случае необходимости
                  предоставления Персональных данных для достижения указанных
                  выше целей третьим лицам, а равно как при привлечении третьих
                  лиц к оказанию услуг, реализации товаров в моих интересах,
                  передачи Оператором принадлежащих ему функций и полномочий
                  иному лицу, Оператор вправе в необходимом объеме раскрывать
                  для совершения вышеуказанных действий информацию обо мне лично
                  (включая мои Персональные данные) таким третьим лицам, их
                  агентам и иным уполномоченным ими лицам, а также предоставлять
                  таким лицам соответствующие документы, содержащие такую
                  информацию.
                </p>
                <br />
                <p>
                  Также настоящим признаю и подтверждаю, что настоящее согласие
                  считается данным мною любым третьим лицам, указанным выше, с
                  учетом соответствующих изменений, и любые такие третьи лица
                  имеют право на обработку Персональных данных на основании
                  настоящего согласия при условии соблюдения требований
                  законодательства (Федеральный закон от 27.07.2006 N 152-ФЗ «О
                  персональных данных»).
                </p>
                <br />
                <p>
                  Настоящее согласие дается мной, в том числе, для целей
                  продвижения на рынке (в том числе путем осуществления прямых
                  контактов со мной с помощью средств связи, включая почтовые
                  отправления, телефонную связь, электронные средства связи, в
                  том числе SMS-сообщения, факсимильную связь и другие средства
                  связи) продуктов (услуг) Оператора, совместных продуктов
                  Оператора и третьих лиц, продуктов (товаров, работ, услуг)
                  третьих лиц.
                </p>
                <br />
                <p>
                  Настоящее согласие предоставляется на осуществление любых
                  действий в отношении моих персональных данных, которые
                  необходимы или желаемы для достижения указанных выше целей,
                  включая, без ограничения: сбор, систематизацию, накопление,
                  хранение, уточнение (обновление, изменение), использование,
                  распространение (в том числе передача), обезличивание,
                  блокирование, уничтожение, трансграничную передачу
                  Персональных данных, а также осуществление любых иных действий
                  с моими Персональными данными с учетом действующего
                  законодательства. Обработка Персональных данных осуществляется
                  Оператором с применением следующих основных способов (но, не
                  ограничиваясь ими): хранение, запись на электронные носители и
                  их хранение, составление перечней, маркировка.
                </p>
                <br />
                <p>
                  Настоящее согласие дается на срок действия отдельно
                  заключенного Договора и в течение 50 (пятидесяти) лет с даты
                  прекращения или исполнения Договора, в соответствии с
                  действующим законодательством Российской Федерации.
                </p>
                <br />
                <p>
                  Отзыв согласия может быть произведен в письменной форме не
                  ранее даты прекращения Договора/даты исполнения обязательств в
                  соответствии с Договором. При этом Оператор хранит
                  персональные данные в течение срока хранения документов,
                  установленного действующим законодательством России, а в
                  случаях, предусмотренных законодательством, передает
                  уполномоченным на то нормативно-правовыми актами органам
                  государственным власти. Я согласен с тем, что по моему
                  письменному требованию уведомление об уничтожении персональных
                  данных будет вручаться мне (моему представителю) по месту
                  нахождения Оператора.
                </p>
                <br />
                <p>
                  При предоставлении мною персональных данных иных лиц, я
                  гарантирую, что согласие вышеуказанных лиц на предоставление
                  их персональных данных мною получено и несу ответственность в
                  случае предъявления каких-либо претензий к Оператору
                  вследствие несоблюдения данного условия. Я непосредственно,
                  либо через своего представителя, предоставляя информацию о
                  своих абонентских номерах подвижной (мобильной) связи, а также
                  абонентских номерах иных лиц или их уполномоченных
                  представителей, даю свое согласие и гарантирую наличие
                  согласия владельцев и пользователей абонентских номеров на
                  получение голосовых, автоматических голосовых и/или
                  смс-уведомлений и/или иных уведомлений с помощью электронных
                  средств связи в пользу Оператора и указанных им выше третьих
                  лиц, а также подтверждаю наличие желания владельцев и
                  пользователей этих абонентских номеров получать вышеуказанные
                  уведомления и гарантирую, что согласие всех лиц, контакты
                  которых я предоставил, на предоставление их контактов и
                  получение ими уведомлений, мною получено и несу
                  ответственность в случае предъявления каких-либо претензий
                  Оператору вследствие несоблюдения данного условия.
                </p>
                <br />
                <p>
                  Я понимаю, что мое согласие с условиями Договора купли-продажи
                  товара дистанционным способом, Пользовательским соглашением,
                  Ограничением на использование информации, Сообщением об
                  авторских правах, Политикой конфиденциальности, Согласием на
                  обработку персональных данных, в соответствии с текстом
                  вышеуказанных документов, опубликованных на Сайте, будет
                  выражено путем проставления отметки напротив графы "Согласен"
                  при регистрации на сайте или в любой момент пользования сайтом
                  при отдельном запросе. В случае моего несогласия с
                  вышеуказанными правилами и документами, я обязуюсь прекратить
                  использование сайта и сервисов, принадлежащих Оператору
                  используя функцию "Удаления регистрации" или обращения в офис
                  регистрации.
                </p>
                <br />
                <label>
                  <input
                    type={"checkbox"}
                    checked={isPolicy}
                    onChange={setPolicy}
                  />{" "}
                  Согласен на обработку персональных данных
                </label>
              </TextSC>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}>
                <button onClick={() => setMenuState("menu")}>Назад</button>
                <ReplyIconSC
                  onClick={() => {
                    setMenuState("main");
                    handleClose("")();
                  }}
                  src="ReplyIcon.svg"
                />
              </div>
              <TextSC>
                <TitleSC>
                  ДОГОВОР КУПЛИ-ПРОДАЖИ ТОВАРОВ В ИНТЕРНЕТ-МАГАЗИНЕ
                </TitleSC>
                <p>
                  <b>Продавец</b> — Индивидуальный предприниматель Кудрявцева
                  Елена Викторовна ОГРН 323450000018362, ИНН 450101845134
                </p>
                <br />
                <p>
                  <b>Покупатель</b> — любое полностью дееспособное физическое
                  лицо, акцептировавшее настоящую публичную оферту (договор) на
                  приведенных ниже условиях.
                </p>
                <br />
                <p>
                  <b>Интернет-магазин</b> — официальный интернет-магазин
                  Продавца, расположенный по адресу{" "}
                  <a href={`https://${DOMAIN_NAME}/`}>https://{DOMAIN_NAME}/</a>
                  . Понятия Интернет-магазин и Сайт являются равносильными и
                  трактуются аутентично по контексту ниже.
                </p>
                <br />
                <p>
                  <b>Товар</b> — объект материального мира, не изъятый из
                  гражданского оборота и предлагаемый к продаже в
                  Интернет-магазине.
                </p>
                <br />
                <p>
                  <b>Заказ</b> – Товар и услуги, согласованные Покупателем и
                  Продавцом, относительно которых у Покупателя и Продавца
                  возникают взаимные обязательства на согласованных Покупателем
                  и Продавцом условиях.
                </p>
                <br />
                <b>
                  <i>2. ОБЩИЕ УСЛОВИЯ.</i>
                </b>
                <br />
                <br />
                <p>
                  2.1. Настоящий договор (далее по тексту – «Договор»), а также
                  информация о Товаре, представленная на Сайте, являются
                  публичной офертой в соответствии со ст.435 и ч.2 ст.437
                  Гражданского кодекса РФ.
                </p>
                <p>2.2. В соответствии с Договором:</p>
                <p>
                  1) Продавец обязуется передать в собственность Покупателя
                  заказанный (согласованный Покупателем и Продавцом) Товар;
                </p>
                <p>
                  2) Покупатель обязуется принять и оплатить заказанный Товар и
                  его доставку.
                </p>
                <p>
                  2.3. Цены на Товар определяются Продавцом в одностороннем
                  бесспорном порядке и указываются на страницах
                  Интернет-магазина. Цена Товара определяется в рублях
                  Российской Федерации и включает в себя налог на добавленную
                  стоимость.
                </p>
                <p>
                  2.4. Право собственности на заказанный Товар переходит к
                  Покупателю при доставке транспортными компаниями - с момента
                  передачи Продавцом Товара в транспортную компанию.
                </p>
                <p>
                  Риск случайной гибели или повреждения Товара переходит к
                  Покупателю с момента фактической передачи Товара Покупателю.
                </p>
                <p>
                  2.5. Акцептом Договора является момент размещения Покупателем
                  Заказа согласно Договору.
                </p>
                <p>
                  2.6. Размещение Заказа Покупателем осуществляется путем
                  совершения действий, указанных в Правилах продажи.
                </p>
                <p>
                  2.7. Продавец имеет право в одностороннем порядке вносить
                  изменения в Договор, в том числе изменять цены на Товар и
                  стоимость сопутствующих услуг, способы и сроки оплаты и
                  доставки Товара, помещая их на страницах Интернет-магазина.
                  Все изменения вступают в силу немедленно после указанной
                  публикации, и считаются доведенными до сведения Покупателя с
                  момента такой публикации.
                </p>
                <p>
                  2.8. Отзыв оферты (Договора) может быть осуществлён Продавцом
                  в любое время, но это не является основанием для отказа от
                  обязательств Продавца по уже заключённым договорам. Продавец
                  обязуется разместить уведомление об отзыве оферты в своем
                  Интернет-магазине, не менее чем за 12 часов до факта
                  наступления события отзыва (приостановки) действия оферты.
                </p>
                <br />
                <b>
                  <i>3. ОПЛАТА ЗАКАЗА.</i>
                </b>
                <br />
                <br />
                <p>
                  3.1. Оплата Заказа осуществляется в рублях Российской
                  Федерации.
                </p>
                <p>
                  3.2. Общая стоимость Заказа не включает стоимость заказанного
                  Товара и его доставку, за исключением случаев, когда выбранный
                  способ доставки позволяет произвести оплату доставки при
                  получении Товара, по согласованию с Покупателем.
                </p>
                <p>
                  3.3. Покупатель осуществляет предварительную оплату Заказа в
                  размере 100% общей стоимости Заказа, за исключением случаев,
                  когда выбранный способ доставки позволяет произвести оплату
                  Заказа при получении его Покупателем. Продавец оставляет за
                  собой право в одностороннем порядке определять способ и
                  порядок оплаты по конкретному Заказу.
                </p>
                <p>3.4. Способы оплаты Заказа определены в Правилах Продажи.</p>
                <p>
                  3.5. Датой оплаты считается дата поступления денежных средств
                  на расчетный счет Продавца согласно его платежным реквизитам,
                  указанным в разделе 1 настоящего Договора.
                </p>
                <p>
                  3.6. В случае оплаты электронными деньгами (банковская карта,
                  Яндекс.Деньги и др.) или переводом (через банк или Почтой
                  России) Продавец направляет Покупателю электронную копию чека
                  на указанный в заказе адрес электронной почты в день
                  поступления денежных средств на расчетный счет Продавца.
                </p>
                <br />
                <b>
                  <i>4. ДОСТАВКА ТОВАРА.</i>
                </b>
                <br />
                <br />
                <p>
                  4.1. Доставка Товара Покупателю осуществляется в пределах
                  территории Российской Федерации.
                </p>
                <p>
                  4.2. Стоимость доставки Товара по территории РФ определяется
                  Тарифами используемой службы доставки. Способы доставки Заказа
                  определены в Правилах Продажи.
                </p>
                <p>
                  4.3. Место передачи Товара Покупателю определяется по адресу,
                  указанному в размещенном Заказе.
                </p>
                <p>
                  4.4. Передача Товара Покупателю осуществляется только после
                  полной оплаты Покупателем стоимости Заказа.
                </p>
                <p>
                  4.5. Передача заказанного Товара Покупателю может
                  осуществляться в согласованном Покупателем и Продавцом пункте
                  выдачи Товара, в зависимости от выбранного способа доставки.
                </p>
                <br />
                <b>
                  <i>5. ВОЗВРАТ ТОВАРА.</i>
                </b>
                <br />
                <br />
                <p> 5.1. Возврат товара надлежащего качества. </p>
                <p>
                  1) Покупатель вправе отказаться от заказанного Товара в любое
                  время до его получения, а после получения Товара — в течение
                  14 дней, не считая дня покупки. Вернуть товар надлежащего
                  качества можно при соблюдении следующих условий:
                </p>
                <p>товар не был в употреблении;</p>
                <p>
                  сохранен товарный вид (этикетки, ярлыки, оригинальная упаковка
                  производителя) и потребительские свойства;
                </p>
                <p>
                  приложена копия квитанции (накладная) об оплате посылки с
                  заполненным бланком возврата.
                </p>
                <p>
                  2) Продавец возвращает Покупателю сумму оплаты за Заказ за
                  исключением расходов на доставку в течение 10 дней с даты
                  получения Продавцом письменного заявления Покупателя. Если
                  возврат связан с ошибкой Продавца, то возвращаются и расходы
                  на доставку.
                </p>
                <p> 5.2. Возврат товара ненадлежащего качества.</p>
                <p>
                  1) Покупатель вправе вернуть товар ненадлежащего качества и
                  потребовать возврата уплаченной суммы. Также он может
                  потребовать устранения недостатков, уменьшения цены или замены
                  товара.
                </p>
                <p>
                  2) Продавец обязан рассмотреть претензии и произвести возврат
                  денежных средств в течение 10 дней или заменить товар в
                  течение 7 дней с момента предъявления требования, если иное не
                  предусмотрено.
                </p>
                <br />
                <b>
                  <i>6. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ. ПРИМЕНИМОЕ ПРАВО.</i>
                </b>
                <br />
                <br />
                <p>
                  6.1. К отношениям между Покупателем и Продавцом применяется
                  законодательство Российской Федерации.
                </p>
                <p>
                  6.2. Споры разрешаются путем переговоров. Если соглашение не
                  достигнуто, спор передается в суд в соответствии с
                  законодательством РФ.
                </p>
                <p>
                  6.3. Документы и переписка, связанные с исполнением Договора,
                  имеют равную юридическую силу с оригиналами при передаче через
                  Интернет или электронные средства связи.
                </p>
                <p>
                  6.4. Признание судом недействительности любого положения
                  Договора не влечет недействительность остальных положений.
                </p>
                <p>
                  6.5. Приложения к Договору являются его неотъемлемой частью.
                </p>
              </TextSC>
            </>
          )}
        </ContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  ReplyIconSC,
  TextSC,
  TitleSC,
} = useRequisitesStyles();

export default React.memo(Requsites);
