import React, { InputHTMLAttributes } from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { useInputStyled } from "./style";

interface IInputUIProps {
  error: boolean;
  value: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange: () => void;
  styleComponents?: {
    InputStyle?: StyledComponent<
      "input",
      DefaultTheme,
      {
        isError?: boolean | undefined;
      },
      never
    >;
  };
}

const InputUI = ({
  error,
  value,
  onChange,
  inputProps,
  styleComponents = {},
}: IInputUIProps) => {
  const { InputStyle } = styleComponents;
  const Input = InputStyle || InputSC;

  return (
    <Input {...inputProps} isError={error} value={value} onChange={onChange} />
  );
};

const { InputSC } = useInputStyled();

export default React.memo(InputUI);
