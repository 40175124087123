// @ts-nocheck
import { useState, useEffect, useRef } from "react";

import { useProductCardStyles } from "./ProductCardStyles";

import FullVisionCard from "./FullVision";
import axios from "axios";
import { useCardFull } from "./FullVision/useFullVision";
import { BASE_URL } from "lib/constants/constants";
import styled from "styled-components";
import useStore from "../../store";
import FormFeedBack from "../FormFeedBack";

const ProductCard = ({
  id,
  image,
  title,
  description,
  price,
  vendor_code,
  isAdded,
}) => {
  const { addCard } = useStore();
  const Cardref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [useCardFull, setUseCardFull] = useState({});

  useEffect(() => {
    const windowSize = () => {
      if (window.innerWidth >= 1890) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []);

  const handleClose = (e) => {
    // e.stopPropaganation();

    setIsOpen(false);
  };

  const handleClick = async () => {
    setIsOpen(true);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addCard({
      name: title,
      price,
      vendor_code,
      image,
      description: "",
      quantity: 0,
    });
    increase();
  };

  return (
    <>
      <CardSC ref={Cardref} onClick={handleClick}>
        <ImgSC src={`${BASE_URL}/${image}`} />
        <InfoContainerSC>
          <TitleSC>{title}</TitleSC>
          <DescriptionSC>{description}</DescriptionSC>
        </InfoContainerSC>
        <NumberContainerSC>
          <TextContainerSC>
            <VendorCodeSC>{vendor_code}</VendorCodeSC>
            <PriceSC>{price} ₽</PriceSC>
          </TextContainerSC>
          <ButtonSC onClick={handleAddToCart}>Добавить</ButtonSC>
        </NumberContainerSC>
      </CardSC>
      {isOpen && (
        <FullVisionCard
          title={title}
          description={description}
          price={price}
          vendor={vendor_code}
          image={image}
          doClose={handleClose}
        />
      )}
    </>
  );
};

const AddedBasketSC = styled("button")`
  width: 100%;
  height: 30px;
  backdrop-filter: blur(2.5px);
  margin-top: 10px;

  border-radius: 16px;
  border: none;

  cursor: pointer;

  background: rgba(128, 200, 74, 0.6);
  -webkit-backdrop-filter: blur(2.5px);
  color: #fff;
  font-weight: 600;
  text-align: center;
`;

const ButtonSC = styled("button")`
  cursor: pointer;

  font-size: 14px;
  font-weight: 600;

  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  color: #fff;
  background: #878080;
`;

const {
  CardSC,
  ImgSC,
  InfoContainerSC,
  NumberContainerSC,
  TitleSC,
  DescriptionSC,
  VendorCodeSC,
  PriceSC,
  TextContainerSC,
} = useProductCardStyles();

export default ProductCard;
