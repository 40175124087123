import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const InputSC = styled("input")<{ isError?: boolean }>`
  width: 100%;
  height: 60px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* border-radius: 10px; */
  padding: 0 14px;
  border: ${({ theme, isError }) =>
    isError ? `2px solid ${theme.error}` : "none"};
  &::placeholder {
    opacity: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: ${({ theme, isError }) =>
      isError ? theme.error : theme.placeholder};
    ${mediaCSS(786)} {
      font-size: 14px;
      line-height: 19px;
    }
  }
  ${mediaCSS(600)} {
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    padding: 0 27px;
  }
`;

export const useInputStyled = () => ({
  InputSC,
});
