import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useThemeInputStyle } from "./style";
import InputUI from "UI/InputUI/InputUI";

const ThemeInput = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="theme"
      control={control}
      rules={{ required: true }}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            styleComponents={{ InputStyle: InputforTheme }}
            inputProps={{ placeholder: "Тема запроса *" }}
          />
        );
      }}
    />
  );
};

const { InputforTheme } = useThemeInputStyle();

export default React.memo(ThemeInput);
