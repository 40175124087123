import React from "react";
import Header from "components/Header";
import Main from "components/Main";

import ArrowTop from "components/ArrowTop";

import "./styles/globals.css";

function App() {
  return (
    <>
      <Header />
      <Main />
      <ArrowTop />
    </>
  );
}

export default App;
