import styled from "styled-components";

const ButtonTopSC = styled("button")`
  /* position: absolute;

  right: 50px;
  bottom: 50px; */

  background: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  cursor: pointer;

  @media (max-width: 812px) {
    display: none;
  }
`;

export const useArrowTopStyles = () => ({ ButtonTopSC });
