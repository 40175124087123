import { useArrowTopStyles } from "./ArrowTopStyles";

import { useArrowTop } from "./useArrowTop";

const ArrowTop = () => {
  const { isVisible, scrollTop } = useArrowTop();
  return (
    isVisible && (
      <ButtonTopSC onClick={scrollTop}>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="28" r="28" fill="white" />
          <mask
            id="mask0_331_1123"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="12"
            y="12"
            width="32"
            height="32">
            <rect
              x="12"
              y="44"
              width="32"
              height="32"
              transform="rotate(-90 12 44)"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_331_1123)">
            <path
              d="M35.0333 26.2667C34.7667 26.5334 34.4444 26.6612 34.0667 26.6501C33.6889 26.639 33.3667 26.5001 33.1 26.2334L29.3333 22.4667V37.3334C29.3333 37.7112 29.2055 38.0279 28.95 38.2834C28.6944 38.539 28.3778 38.6667 28 38.6667C27.6222 38.6667 27.3055 38.539 27.05 38.2834C26.7944 38.0279 26.6667 37.7112 26.6667 37.3334V22.4667L22.8667 26.2667C22.6 26.5334 22.2833 26.6667 21.9167 26.6667C21.55 26.6667 21.2333 26.5334 20.9667 26.2667C20.7 26.0001 20.5667 25.6834 20.5667 25.3167C20.5667 24.9501 20.7 24.6334 20.9667 24.3667L27.0667 18.2667C27.2 18.1334 27.3444 18.039 27.5 17.9834C27.6555 17.9279 27.8222 17.9001 28 17.9001C28.1778 17.9001 28.3444 17.9279 28.5 17.9834C28.6555 18.039 28.8 18.1334 28.9333 18.2667L35.0667 24.4001C35.3111 24.6445 35.4333 24.9501 35.4333 25.3167C35.4333 25.6834 35.3 26.0001 35.0333 26.2667Z"
              fill="#6F6F6F"
            />
          </g>
        </svg>
      </ButtonTopSC>
    )
  );
};

const { ButtonTopSC } = useArrowTopStyles();

export default ArrowTop;
