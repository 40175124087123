//@ts-nocheck
import { useRef, useEffect } from "react";

import { useFullVisionStyle } from "./FullVisionStyles";

import { useFullVisible } from "./useFullVision";
import { BASE_URL } from "../../../lib/constants/constants";

const FullVisionCard = ({
  title,
  description,
  vendor,
  price,
  doClose,
  image,
}) => {
  const { isOpen, handleClosed, handleClick } = useFullVisible();

  const handleCloseClick = (e) => {
    if (e.target.closest(".WrapperSC")) {
      return;
    }

    doClose();
  };

  return (
    // isOpen && (
    <ExternalWrapperSC onClick={handleCloseClick}>
      <WrapperSC className="WrapperSC">
        <ButtonExit onClick={doClose} />
        <ContainerSC>
          <ImgSC src={`${BASE_URL}/${image}`} />
          <FlexDirectionColumnSC>
            <TitleSC>{title}</TitleSC>
            <FlexGrow>
              <DescriptionSC>{description}</DescriptionSC>
            </FlexGrow>
          </FlexDirectionColumnSC>
        </ContainerSC>
        <ContainerRow>
          <VendorCodeSC>{vendor}</VendorCodeSC>
          <PriceSC>{price} ₽</PriceSC>
        </ContainerRow>
      </WrapperSC>
    </ExternalWrapperSC>
    // )
  );
};

const {
  ExternalWrapperSC,
  WrapperSC,
  TitleSC,
  DescriptionSC,
  ContainerRow,
  PriceSC,
  VendorCodeSC,
  ButtonExit,
  ImgSC,
  FlexGrow,
  ContainerSC,
  FlexDirectionColumnSC,
} = useFullVisionStyle();

export default FullVisionCard;
