import styled from "styled-components";
import { InputSC } from "UI/InputUI/style";

const InputforTheme = styled(InputSC)`
  height: 1.275rem;
  width: 100%;

  margin-bottom: 1rem;
  padding: 0.64rem 0 20px;
  border: none;
  border-bottom: 0.85px solid #000;

  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;

  color: #0c0e20;

  &::placeholder {
    color: #989898;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  @media (max-width: 812px) {
    width: 100%;

    padding: 0.5rem 1.56rem 20px 0;

    margin-bottom: 0.62rem;

    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
`;

export const useThemeInputStyle = () => ({
  InputforTheme,
});
