import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useFullNameStyle } from "./style";
import InputUI from "UI/InputUI/InputUI";

const EmaiInput = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="email"
      control={control}
      rules={{ required: true }}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            styleComponents={{ InputStyle: InputEmail }}
            inputProps={{ placeholder: "E-mail / Телефон *" }}
          />
        );
      }}
    />
  );
};

const { InputEmail } = useFullNameStyle();

export default React.memo(EmaiInput);
