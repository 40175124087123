import { useAboutCompanyStyles } from "./AboutCompany";

const AboutCompany = () => {
  return (
    <TextContainerSC>
      <TitleCompanySC style={{ alignSelf: "end" }}>доступно</TitleCompanySC>
      <TitleCompanySC style={{ alignSelf: "start" }}>легко</TitleCompanySC>
      <ContainerSC>
        <TitleCompanySC style={{ width: "50%" }}>быстро</TitleCompanySC>
        <DescriptionCompanySC>
          <p
            style={{
              margin: "0 0 15px",
              fontSize: "30px",
              fontWeight: " 900",
            }}>
            Первая специальная техническая компания
          </p>
          Продаём запчасти для спецтехники. Огромный выбор оригинальных
          запчастей по доступным ценам. Любые запчасти для всех марок
          спецтехники. Покупать у нас – надёжно. За качество мы отвечаем!
        </DescriptionCompanySC>
      </ContainerSC>
    </TextContainerSC>
  );
};

const { TextContainerSC, DescriptionCompanySC, TitleCompanySC, ContainerSC } =
  useAboutCompanyStyles();

export default AboutCompany;
