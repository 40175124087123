import styled from "styled-components";

const CardSC = styled("article")`
  width: 248px;
  /* height: 472px; */

  overflow: hidden;

  -webkit-tap-highlight-color: transparent;

  @media (max-width: 1860px) {
    cursor: pointer;
  }

  @media (max-width: 712px) {
    width: 165px;
  }

  @media (max-width: 430px) {
    width: 248px;
  }
`;

const ImgSC = styled("img")`
  display: block;

  object-fit: contain;

  width: 100%;
  /* height: 248px; */

  margin-bottom: 16px;
`;

const InfoContainerSC = styled("div")`
  //firefox
  scrollbar-width: thin;
  scrollbar-color: #e4e6eb transparent;

  &::-webkit-scrollbar {
    width: 8px; /*Ширина скроллбара*/
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e4e6eb;
    height: 26px; /* Высота ползунка */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  overflow-y: scroll;
  padding-bottom: 20px;
  /* max-height: 232px; */
  /* max-height: 205px; */
  height: 205px;

  @media (max-width: 812px) {
    height: 134px;
    overflow-y: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }

  margin-bottom: 8px;
`;

const TextContainerSC = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NumberContainerSC = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative; /* делает контейнер относительным */
  top: -30px; /* сдвигает контейнер наверх */
  background-color: #f5f6f8; /* устанавливает цвет фона */
  padding: 2px; /* добавляет отступ от краев */

  /* position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff; */

  /* position: absolute; */
  /* bottom: 0;
  left: 0; */
`;

const TitleSC = styled("h5")`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 8px;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3rem;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 812px) {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.25rem;

    height: 2.5rem;
  }
`;

const DescriptionSC = styled("p")`
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: 812px) {
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.9375rem;
  }
`;

const VendorCodeSC = styled("p")`
  color: #989898;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;

  @media (max-width: 812px) {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 0.9375rem;
  }
`;

const PriceSC = styled("p")`
  color: #0c0e20;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 812px) {
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;

export const useProductCardStyles = () => ({
  CardSC,
  ImgSC,
  InfoContainerSC,
  NumberContainerSC,
  TitleSC,
  DescriptionSC,
  VendorCodeSC,
  PriceSC,
  TextContainerSC,
});
