import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/icon/CloseIcon.svg";
import useStore from "../../store";
import { createPortal } from "react-dom";
import BasketElements from "./BasketElements";
import BasketForm from "./BasketForm";
import axios from "axios";
import { DISABLE_PAYMENT } from "../../lib/constants/constants";
import policyStore from "../../store/policyStore";

const Basket = ({ handleChangeVisible }) => {
  const { data, removeAll } = useStore();
  const [state, setState] = useState(false);
  const { isPolicy, setPolicy } = policyStore();

  const handleClick = (e) => {
    if (e.target.classList.contains("OutterSC")) {
      handleChangeVisible();
    }
  };

  const handleNextStep = () => {
    setState(!state);
  };

  const totalPrice = data.reduce(
    (accumulator, item) => accumulator + Number(item.price) * item.quantity,
    0,
  );

  return createPortal(
    <OutterSC className={"OutterSC"} onClick={handleClick}>
      {!state && (
        <>
          {data.length > 0 && (
            <ContainerSC>
              <ScrollContainerSC>
                <TopContainerSC>
                  <TextContainerSC>
                    <TitleSC>Корзина</TitleSC>
                    <RemoveAll onClick={removeAll}>Удалить всё</RemoveAll>
                  </TextContainerSC>
                  <IconContainerSC onClick={handleChangeVisible}>
                    <CloseIcon />
                  </IconContainerSC>
                </TopContainerSC>
                <BasketElements data={data} />
              </ScrollContainerSC>
              <ItogoSC>
                <SubtitleSC>Итого:</SubtitleSC>
                <TitleSC>{totalPrice} ₽</TitleSC>
              </ItogoSC>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  margin: "8px 0 0",
                }}>
                <label style={{ color: "black", cursor: "pointer" }}>
                  <input
                    type={"checkbox"}
                    checked={isPolicy}
                    onChange={setPolicy}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Согласен на обработку персональных данных
                </label>
              </div>
              {DISABLE_PAYMENT === "true" ? (
                <OrderButtonSC disabled={!isPolicy} onClick={handleNextStep}>
                  Заказать
                </OrderButtonSC>
              ) : (
                <OrderButtonSC
                  disabled={!isPolicy}
                  onClick={() => {
                    handleChangeVisible();
                    removeAll();
                    window.location.replace(
                      `https://payment.alfabank.ru/payment/constructor/prepay.html?login=r-1_stk&logo=0&def={"name":"amount","value":"${totalPrice}","title":"1-stk.ru"}&currency[]=RUR`,
                    );
                  }}>
                  Перейти к оплате
                </OrderButtonSC>
              )}
            </ContainerSC>
          )}
          {!data.length && (
            <ContainerSC>
              <TopContainerSC>
                <IconContainerSC onClick={handleChangeVisible}>
                  <CloseIcon />
                </IconContainerSC>
              </TopContainerSC>
              <EmptyWrapperSC>
                <TitleSC>Вы ничего не добавили!</TitleSC>
              </EmptyWrapperSC>
            </ContainerSC>
          )}
        </>
      )}
      {state && (
        <ContainerSC>
          <BasketForm
            handleChangeVisible={handleChangeVisible}
            returnBasket={(value: boolean) => setState(value)}
          />
        </ContainerSC>
      )}
    </OutterSC>,
    document.querySelector("#root") as HTMLElement,
  );
};

const ScrollContainerSC = styled("div")`
  flex-grow: 1;

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SubtitleSC = styled("p")`
  font-size: 20px;
  color: #000;
  font-weight: 700;
`;

const ItogoSC = styled("div")`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: start;
`;

const OrderButtonSC = styled("button")`
  margin: 24px auto 0;

  width: 60%;
  max-height: 40px;
  min-height: 40px;
  border-radius: 16px;

  font-size: 16px;
  color: #fff;

  background: #292929;

  cursor: pointer;

  &:disabled {
    background: #333333;
    color: rgba(255, 255, 255, 0.4);
    cursor: initial;
  }

  @media (max-width: 600px) {
    margin-top: 12px;
    width: 100%;
  }
`;

const OutterSC = styled("div")`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.5);

  z-index: 5000;
`;

const ContainerSC = styled("div")`
  width: 60%;
  height: 70%;

  border-radius: 16px;
  padding: 16px;
  background: #fff;

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  @media (max-width: 678px) {
    width: 90%;
  }

  &::-webkit-scrollbar {
    width: 8px; /* Ширина скроллбара */
  }
`;

const TopContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const TextContainerSC = styled("div")`
  display: flex;
  flex-direction: column;
`;

const TitleSC = styled("h5")`
  font-size: 20px;
`;

const IconContainerSC = styled("div")`
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const RemoveAll = styled("button")`
  cursor: pointer;

  background: none;

  font-size: 16px;
`;

const EmptyWrapperSC = styled("div")`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo(Basket);
