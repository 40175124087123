// @ts-nocheck

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Controller, FormProvider } from "react-hook-form";
import { useFormFeedback } from "../../FormFeedBack/useFormFeedBack";
import InputNameBasket from "./NameInputBasket";
import InputEmailBasket from "./EmailInputBasket";
import useStore from "../../../store";
import { ReactComponent as ReturnIcon } from "../../../assets/icon/ExitButton.svg";

const BasketForm = ({ handleChangeVisible, returnBasket }) => {
  const { removeAll } = useStore();
  const { method, handleSubmit, onSubmit } = useFormFeedback();
  const [state, setState] = useState(false);

  const handleClickOrder = () => {
    setState(true);
    removeAll();

    setTimeout(() => {
      handleChangeVisible();
    }, 2000);
  };

  const handleReturnBasket = () => {
    returnBasket(false);
  };

  return (
    <SectionSC>
      {!state && (
        <>
          <FormProvider {...method}>
            <TopContainerSC>
              <TitleSC>
                Отправьте заявку и мы рассчитаем выгодные условия и сроки
                поставки
              </TitleSC>
              <IconContainerSC onClick={handleReturnBasket}>
                <ReturnIcon />
              </IconContainerSC>
            </TopContainerSC>
            <FormSC action="#" onSubmit={handleSubmit(handleClickOrder)}>
              <WrapperSC>
                <InputNameBasket />
                <InputEmailBasket />
              </WrapperSC>
              <ButtonSC type="submit">Отправить</ButtonSC>
            </FormSC>
          </FormProvider>
        </>
      )}
      {state && (
        <SuccessContainerSC>
          <TitleSC>Ваша заявка принята</TitleSC>
          <SubtitleSC>В скором времени мы с вами свяжемся</SubtitleSC>
        </SuccessContainerSC>
      )}
    </SectionSC>
  );
};

const TopContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const SubtitleSC = styled("p")`
  text-align: center;

  @media (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

const SuccessContainerSC = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

const WrapperSC = styled("div")`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  gap: 16px;
`;

const SectionSC = styled("section")`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  /* margin-bottom: 8.25rem; */

  @media (max-width: 812px) {
    width: calc(100% - 15px * 2);
    margin: 0 auto;
  }
`;

const TitleSC = styled("h4")`
  margin: 0 auto 2rem;
  max-width: 70%;

  text-align: center;
  font-weight: 500;
  font-size: 18px;

  @media (max-width: 812px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.875rem;

    margin-bottom: 1.38rem;
  }

  @media (max-width: 500px) {
    max-width: 100%;

    font-size: 0.9rem;
  }
`;

const FormSC = styled("form")`
  width: 80%;
  flex: 1;

  display: flex;

  flex-direction: column;
  gap: 32px;
  //justify-content: center;
  //align-items: center;
  /* flex-wrap: wrap; */

  /* width: 100%; */
  /* width: 89rem; */
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const InputSC = styled("input")``;

const ButtonSC = styled("button")`
  max-height: 40px;
  min-height: 40px;
  width: 100%;
  border-radius: 16px;
  font-size: 16px;
  background-color: #292929;
  color: #fff;

  cursor: pointer;

  &:hover {
    background-color: #242834;
  }
`;

const IconContainerSC = styled("div")`
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export default BasketForm;
