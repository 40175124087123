import React, { useRef } from "react";
import emailjs from "emailjs-com";

export const useEmailJS = () => {
  // ! настоящие данные для отправки формы закомичены

  const serviceId = "service_4w3guyo";
  const templateId = "template_pqn88sr";
  const publickKey = "ybtX18BCCGynNR2C0";
  const form = useRef();

  const onFetchMail = async (formValue: any) => {
    return await emailjs
      .send(serviceId, templateId, formValue, publickKey)
      .then(
        (result) => {},
        (error) => {
          return error;
        },
      );
  };
  return { onFetchMail };
};
