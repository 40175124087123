import { create } from "zustand";
import { IPolicyStore } from "./type";

const policyStore = create<IPolicyStore>((set) => ({
  isPolicy: true,
  setPolicy: () => {
    set((state) => ({ isPolicy: !state.isPolicy }));
  },
}));

export default policyStore;
