/* import { useEffect, useRef, useState } from "react";

export const useFullVisible = (props?) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedCard, setSelectedCard] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleClosed = () => setIsOpen(false);
  const handleOpened = () => setIsOpen(true);

  const handleClick = (e) => {
    setSelectedCard(props);
    setIsOpen(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isOpen]);

  return {
    wrapperRef,
    isOpen,
    handleClosed,
    handleOpened,
    selectedCard,
    setSelectedCard,
  };
}; */

import { useRef, useState, useEffect } from "react";

/* export const useFullVisible = () => {
  const wrapperRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClosed = () => setIsOpen(false);
  const handleOpened = () => setIsOpen(true);

  const handleClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      handleClosed();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return {
    wrapperRef,
    isOpen,
    setIsOpen,
    handleClosed,
    handleOpened,
    selectedCard,
    setSelectedCard,
  };
}; */

export const useFullVisible = () => {
  const wrapperRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClosed = () => setIsOpen(false);
  const handleOpened = () => setIsOpen(true);

  const handleClick = (e) => {
    if (!wrapperRef.current.contains(e.target)) {
      handleClosed();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return {
    wrapperRef,
    isOpen,
    setIsOpen,
    handleClosed,
    handleOpened,
    selectedCard,
    setSelectedCard,
    handleClick,
  };
};
