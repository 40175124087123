import styled, { css } from "styled-components";
import { useHeaderStyle } from "./HeaderStyles";
import { ReactComponent as CartIcon } from "../../assets/icon/CartIcon.svg";

import { useHeader } from "./useHeader";

import FormFeedBack from "components/FormFeedBack";
import { useEffect, useState } from "react";
import Basket from "../Basket";
import Requisites from "./Requisites/Requisites";

type ModalState = "contacts" | "requisites" | "About" | "basket" | "";

const Header = () => {
  const {
    isOpenContact,
    toggleContact,
    toggleForm,
    isOpenForm,
    closeForm,
    totalCount,
  } = useHeader();

  const [basketVisible, setBasketVisible] = useState(false);
  const [requisites, setRequisites] = useState<ModalState>("");

  const handleRequisites = (data: ModalState) => {
    return () => {
      setRequisites(data);
    };
  };

  const handleChangeVisible = () => {
    setBasketVisible(!basketVisible);
  };

  useEffect(() => {
    document.body.style.overflow = basketVisible ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [basketVisible]);

  console.log(process.env.REACT_APP_ENABLE_BASKET);

  return (
    <>
      <HeaderSC>
        <WrapperHeaderSC>
          <DownContactSC $isShow={isOpenContact}>
            <div>
              <a href="mailto:helen_kud@bk.ru">E-mail: helen_kud@bk.ru</a>
              <a href="tel:+79823577837">Телефон: +7 (982) 357 78 37</a>
              <p>
                Офис: Сони Кривой 83 офис 315 <br /> Адрес: 454007, г.
                Челябинск, проспект Ленина, д. 2А/2
              </p>
            </div>
          </DownContactSC>
          <ContainerElementsSC>
            <ContainerSC>
              <ButtonSC onClick={() => setRequisites("requisites")}>
                Покупателям
              </ButtonSC>
              <ButtonSC onClick={toggleForm}>Обратная связь</ButtonSC>
              <ButtonSC onClick={toggleContact}>Контакты</ButtonSC>
              <IconContainerSC onClick={handleChangeVisible}>
                Корзина
                <SpanCountSC>{totalCount}</SpanCountSC>
              </IconContainerSC>
            </ContainerSC>
          </ContainerElementsSC>
        </WrapperHeaderSC>
      </HeaderSC>
      <FormFeedBack isOpenForm={isOpenForm} closeForm={closeForm} />
      {basketVisible && <Basket handleChangeVisible={handleChangeVisible} />}

      <Requisites
        isOpen={requisites === "requisites"}
        handleClose={handleRequisites}
        className="req"
      />
    </>
  );
};

const IconContainerSC = styled("div")`
  position: relative;

  cursor: pointer;

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }

  svg {
    fill: #fff;
  }
`;

const {
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  LinkSC,
  ButtonSC,
  ContainerSC,
  DownContactSC,
  SpanCountSC,
} = useHeaderStyle();

export default Header;
