import styled from "styled-components";

const TextContainerSC = styled("div")`
  z-index: 10;
  position: relative;

  user-select: none;
  /* z-index: 1; */

  color: #fff;
  height: 100%;
  /* width: 90%; */
  padding: 4rem 4.56rem 3.19rem 5.5rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;
  /* margin: 0 auto 72px; */
  /* max-width: 504px; */

  @media (max-width: 1336px) {
    padding: 4rem 1.56rem 3.19rem;
  }

  @media (max-width: 1336px) {
    justify-content: center;
  }
`;

const TitleCompanySC = styled("h1")`
  text-transform: capitalize;
  text-align: center;

  font-size: 5rem;
  font-weight: 600;
  line-height: 7rem;

  @media (max-width: 768px) {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 5rem;

    align-self: center !important;
  }
`;

const ContainerSC = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const DescriptionCompanySC = styled("p")`
  margin-left: auto;

  width: 36.75rem;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  @media (max-width: 1336px) {
    margin-top: 3.31rem;
  }

  @media (max-width: 900px) {
    margin-top: 1.31rem;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }

  @media (max-width: 719px) {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.375rem;

    width: 20.9rem;
  }
`;

export const useAboutCompanyStyles = () => ({
  TextContainerSC,
  TitleCompanySC,
  DescriptionCompanySC,
  ContainerSC,
});
