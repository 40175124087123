import { useEffect, useRef, useState } from "react";

import { useProductsStyles } from "./ProductsStyles";
import ProductCard from "components/ProductCard";
import { useProducts } from "./useProducts";
import FullVisionCard from "components/ProductCard/FullVision";
import useStore from "../../store";

// TODO КЛИК НА КАРТОЧКУ ПОКА НЕ РАБОТАЕТ

const Products = () => {
  const { data } = useStore();
  const { divRef, selectedProduct, setSelectedProduct, cards } = useProducts();

  // const divRef = useRef(null);
  // const [selectedProduct, setSelectedProduct] = useState(null);

  /* function handleClick(props) {
    setSelectedProduct(props);
  }

  useEffect(() => {
    const div = divRef.current;

    const windowSize = () => {
      if (window.innerWidth <= 812) {
        div.addEventListener("click", handleClick);
      } else {
        div.removeEventListener("click", handleClick);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []); */

  // const CasperElements = cards.map((el, i) => (
  //   <ProductCard {...el} key={el.id} />
  // ));

  const CasperElements = cards.map((item, i) => {
    const isAdded = data.some((itemBask) => itemBask.name === item.title);

    return <ProductCard {...item} isAdded={isAdded} key={item.id} />;
  });

  return (
    <>
      <ProductsSC>
        <ProductsTitleSC>Продукция</ProductsTitleSC>
        <ProductContainerSC ref={divRef}>{CasperElements}</ProductContainerSC>
      </ProductsSC>
      <TextContainerSC>
        <SubtitleSC>Офис: Сони Кривой 83 офис 315</SubtitleSC>
        <SubtitleSC>
          Адрес: 454007, г. Челябинск, проспект Ленина, д. 2А/2
        </SubtitleSC>
        <SubtitleSC>
          Индивидуальный предприниматель Кудрявцева Елена Викторовна
        </SubtitleSC>
        <SubtitleSC>ИНН: 450101845134</SubtitleSC>
      </TextContainerSC>
      {/* <FullVisionCard /> */}
    </>
  );
};

const {
  ProductsSC,
  SubtitleSC,
  ProductsTitleSC,
  TextContainerSC,
  ProductContainerSC,
} = useProductsStyles();

export default Products;
