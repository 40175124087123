import { useEffect, useState } from "react";

export const useArrowTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Скроллит на самый верх
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const changeIsVisible = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
        return;
      }
      setIsVisible(false);
    };

    document.addEventListener("scroll", changeIsVisible);

    return () => {
      document.addEventListener("scroll", changeIsVisible);
    };
  }, []);
  return {
    isVisible,
    scrollTop,
  };
};
