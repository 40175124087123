import styled from "styled-components";

const ProductsSC = styled("section")`
  color: #0c0e20;

  /* width: calc(100% - 172px * 2); */
  width: 85%;
  margin: 0 auto;

  background: #f5f6f8;
  padding: 48px 72px 32px 72px;

  @media (max-width: 950px) {
    /* width: calc(100% - 15px * 2); */
    padding: 48px 40px 76px 40px;
  }
  @media (max-width: 812px) {
    width: calc(100% - 15px * 2);
    padding: 0.94rem 0.31rem 0.31rem;

    margin-bottom: 1.87rem;
  }
`;

const ProductsTitleSC = styled("h2")`
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;

  text-align: center;

  margin-bottom: 4.5rem;

  @media (max-width: 812px) {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;

    margin-bottom: 0.94rem;
  }
`;

const ProductContainerSC = styled("div")`
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));

  /* gap: 48px; */
  grid-gap: 0 3rem;

  justify-items: center;
  align-items: center;

  @media (max-width: 812px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.51rem;
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* @media (max-width: 1920px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ;
  } */

  /* @media (max-width: 1428px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1394px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1078px) {
    grid-template-columns: repeat(2, 1fr);
  } */
  /* @media (max-width: 812px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.51rem;
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
  } */
`;

const TextContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 32px;
  padding: 0 8px;
`;

const SubtitleSC = styled.p`
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const useProductsStyles = () => ({
  ProductsSC,
  ProductsTitleSC,
  ProductContainerSC,
  TextContainerSC,
  SubtitleSC,
});
